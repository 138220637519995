<template>
  <div class="full-width full-height" id="chartBar"></div>
</template>

<script>
import echarts from 'echarts';
import { mapMutations, mapActions, mapState } from 'vuex';
export default {
  name: 'ChartBar',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      backExcepPath: (state) => state.resource.backExcepPath,
    }),
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.renderChartLine();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.renderChartLine();
    });
  },
  methods: {
    ...mapActions(['getExceptionList']),
    ...mapMutations(['setBackExcepPath']),
    renderChartLine() {
      let obj = document.getElementById('chartBar'); //"abnormal-chart"
      if (!obj) {
        return false;
      }
      //console.log("pieChartData=>", pieChartData, pieChartDataTotal);
      let myChart = echarts.init(obj);
      let legendArr = this.data.map((item) => item.name);
      let dataArr = this.data.map((item) => item.value);
      //var that = this;
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: true,
          trigger: 'item',
        },
        legend: {
          show: false,
          // orient: 'vertical',
          left: 'center',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '0',
          bottom: '30%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 1)',
            },
          },
          /* axisLabel: {
            rotate: -35,
          }, */
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          data: legendArr,
        },
        yAxis: {
          type: 'value',
          splitNumber: 3,
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(134, 179, 233, 0.1)'],
              width: 1,
              type: 'solid',
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisTick: {
            lineStyle: {
              color: 'rgba(134, 179, 233, 0.1)',
            },
          },
          axisLabel: {
            color: 'rgba(134, 179, 233, 1)',
          },
        },

        series: [
          {
            name: '故障',
            type: 'bar',

            itemStyle: {
              color: '#3A689F',
            },
            barWidth: '15px',
            data: dataArr,
          },
        ],
      });
      window.addEventListener('resize', () => {
        myChart.resize();
      });
      myChart.off('click');
      myChart.on('click', (params) => {
        let type = params.componentType;
        if (type === 'series') {
          let vo = this.data[params.dataIndex];

          this.getExceptionList({ organizationId: vo.id }).then((data) => {
            if (data.length > 0) {
              let backPath = Array.from(
                new Set([...this.backExcepPath, vo.id])
              );
              /*               console.error('haha-----------', backPath); */
              this.setBackExcepPath(backPath);
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
