<template>
  <div class="full-width full-height bg-blue  text-lightblue p-md">
    <back url="/resource/index" />
    <div class="m-t-sm" style="height:calc(100% - 40px);">
      <vue-scroll :ops="$root.scrollOpsY" style="height:100%">
        <div class="m-b-sm">
          <div class="hd">
            <span
              class="relative p-w-md before-line flex items-center text-white"
              >异常数：{{ exceptionTotal
              }}<i
                class="icon-undo m-l-md pointer"
                v-if="backExcepFlag"
                @click="backHandle"
              ></i
            ></span>
          </div>
          <div class="het1">
            <chart-bar :data="exceptionData" />
          </div>
        </div>
        <div class="m-b-sm flex items-center">
          <div class="flex-one">
            <div class="hd">
              <span
                class="relative p-w-md before-line flex items-center text-white"
                >各省异常占比TOP5</span
              >
            </div>
            <div class="het1">
              <chart-bar-run-com el="chart7" :data="cameraType" />
            </div>
          </div>
          <div class="flex-one">
            <div class="hd m-b-sm">
              <span
                class="relative p-w-md before-line flex items-center text-white"
                >异常类型统计</span
              >
            </div>
            <div class="m-l-sm bd-s-l">
              <div
                class="flex items-center progressor"
                v-for="item in progressArr"
                :key="item.id"
              >
                <div class="flex-one">
                  <a-progress
                    :percent="Number(item.percent)"
                    :show-info="false"
                    :strokeColor="item.color"
                  />
                </div>
                <span class="text-right wd1 flex-shrink">{{
                  item.number
                }}</span>
                <span class="text-right wd2 flex-shrink">{{ item.text }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false">
          <div class="hd flex items-center justify-between m-b-sm">
            <span
              class="relative p-w-md before-line flex items-center text-white"
              >异常列表</span
            >
            <div class="flex items-center">
              <a-select
                placeholder="是否处理"
                style="width: 120px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择故障类型"
                style="width: 120px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择管辖单位"
                style="width: 120px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-range-picker
                :placeholder="['故障上报开始时间', '故障上报结束时间']"
                style="width: 160px"
                class="m-r-xs"
                size="small"
                show-time
              ></a-range-picker>
              <a-range-picker
                :placeholder="['处理开始时间', '处理结束时间']"
                style="width: 160px"
                class="m-r-xs"
                size="small"
                show-time
              ></a-range-picker>
              <a-button type="primary" size="small" class="m-r-sm btn-search">
                搜索
              </a-button>
              <a-button class="btn-reset" size="small">
                重置
              </a-button>
              <span class="span-line m-l-md m-r-md"></span>
              <a-button class="btn-export" size="small">
                批量下载
              </a-button>
            </div>
          </div>
          <div class="relative full-width">
            <a-table
              :columns="columns"
              :data-source="data"
              :pagination="paginationOpt"
              :scroll="{ x: 'calc(700px + 50%)' }"
              bordered
            >
              <span
                slot="customStatus"
                slot-scope="text"
                style="color: #4271AA"
              >
                {{ text }}
              </span>
            </a-table>
            <span class="absolute l0 b20 text-lightblue">共有90条</span>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import Back from '@/components/Back';
import ChartBar from '@/components/ChartBar';
import ChartBarRunCom from '@/components/ChartBarRunCom';
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'CameralException',
  data() {
    return {
      /*  cameralType: {
        name: '异常总数',
        count: 100,
        showTitle: true,
      }, */
      progressArr: [
        {
          id: 0,
          color: '#8CB7EB',
          percent: '30',
        },
        {
          id: 1,
          color: '#81AADD',
          percent: '40',
        },
        {
          id: 2,
          color: '#7299CA',
          percent: '50',
        },
        {
          id: 3,
          color: '#6287B7',
          percent: '60',
        },
        {
          id: 4,
          color: '#4F719F',
          percent: '70',
        },
        {
          id: 5,
          color: '#3E5D8A',
          percent: '80',
        },
        {
          id: 6,
          color: '#304D78',
          percent: '90',
        },
        {
          id: 7,
          color: '#25406A',
          percent: '100',
        },
      ],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 20, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          // this.searchCameraFrom(); //显示列表的接口名称
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          // this.searchCameraFrom();
          // this.searchCameraFromTest();
        },
      },
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) =>
            `${(this.paginationOpt.current - 1) *
              this.paginationOpt.defaultPageSize +
              index +
              1}`,
          width: 80,
        },
        {
          title: '省份',
          dataIndex: 'area',
          key: 'area',
          width: 200,
          // ellipsis: true,
        },
        {
          title: '路公司',
          dataIndex: 'company',
          key: 'company',
          width: 200,
          // ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organization',
          key: 'organization',
          width: 120,
          // ellipsis: true,
        },
        {
          title: '所属路线',
          dataIndex: 'route',
          key: 'route',
          width: 120,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameralName',
          key: 'cameralName',
          width: 220,
          // ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'kmile',
          key: 'kmile',
          width: 120,
        },
        {
          title: '故障类型',
          dataIndex: 'exceptionType',
          key: 'exceptionType',
          width: 120,
        },

        {
          title: '故障上报时间',
          dataIndex: 'exceptionDate',
          key: 'exceptionDate',
          width: 120,
        },
        {
          title: '处理时间',
          dataIndex: 'dealTime',
          key: 'dealTime',
          width: 120,
        },
        {
          title: '处理结果',
          dataIndex: 'dealResult',
          key: 'dealResult',
          width: 100,
        },
        /* {
          title: '摄像机状态',
          dataIndex: 'status',
          key: 'status',
          width: 100,
          scopedSlots: { customRender: 'customStatus' },
          // ellipsis: true,
        }, */
      ],
      data: [
        {
          key: 0,
          area: '浙江省/衢州市/江山区',
          organization: 'grass',
          route: 'G3京台高速',
          kmile: 'k1518+750',
          /* potName: '九华山隧道', */
          category: '道路沿线',
          direction: '上行',
          status: '正常',
        },
      ],
      selectedRowKeys: [],
      cameraType: {},
    };
  },
  components: {
    Back,
    ChartBar,
    ChartBarRunCom,
  },
  computed: {
    ...mapState({
      exceptionList: (state) => state.resource.exceptionList,
      errorDetails: (state) => state.resource.errorDetails,
      backExcepFlag: (state) => state.resource.backExcepFlag,
      backExcepPath: (state) => state.resource.backExcepPath,
    }),
    exceptionTotal() {
      return this.exceptionList.reduce((total, cur) => {
        return total + cur.inerror;
      }, 0);
    },
    exceptionData() {
      return this.exceptionList.map((item) => {
        return { value: item.inerror, name: item.name, id: item.id };
      });
    },
    /* cameraType() {
       let otherExceptionList = sortExceptionList.slice(6);
       */
    /* let total = this.exceptionData.reduce((total,cur)=>{
        return total+cur.value;
      },0);
      return {
        name: '异常总数',
        count: total,
        showTitle: true,
      }; 
    },*/
  },
  watch: {
    exceptionData() {
      this._getChartPieData();
    },
    errorDetails() {
      this._getChartExceptData();
    },
  },
  mounted() {
    this.getExceptionList({ organizationId: '' });
  },
  methods: {
    ...mapActions(['getExceptionList']),
    ...mapMutations(['setBackExcepPath']),
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    _getChartPieData() {
      let sortData = this.exceptionData.sort((a, b) => {
        return a.value < b.value;
      });
      let total = this.exceptionData.reduce((total, cur) => {
        return total + cur.value;
      }, 0);
      let otherData = sortData.length >= 7 ? sortData.slice(6) : [];
      let firstData =
        sortData.length >= 6
          ? sortData.slice(0, 5)
          : sortData.length <= 0
          ? []
          : sortData.slice(0, sortData.length);
      let otherTotal = otherData.reduce((total, cur) => {
        return total + cur.value;
      }, 0);
      let otherDataObj = {};
      if (otherData.length > 0) {
        otherDataObj = {
          name: '其他',
          value: otherTotal,
        };
      }

      let data = [...firstData, otherDataObj];
      this.cameraType = {
        name: '故障总数',
        count: total,
        showTitle: true,
        data,
      };
    },
    _getChartExceptData() {
      let keys = {
        aTotal: '摄像机不在线',
        bTotal: '信号丢失,黑屏',
        cTotal: '图像被遮挡',
        dTotal: '图像模糊',
        eTotal: '亮度故障',
        fTotal: '图像冻结',
        gTotal: '有噪声',
        hTotal: '有闪烁',
        iTotal: '有滚动条纹',
      };
      let colorKeys = {
        aTotal: '#8CB7EB',
        bTotal: '#81AADD',
        cTotal: '#7299CA',
        dTotal: '#6287B7',
        eTotal: '#4F719F',
        fTotal: '#3E5D8A',
        gTotal: '#304D78',
        hTotal: '#25406A',
        iTotal: '#25406c',
      };
      let arr = this.errorDetails.map((item) => item.number);
      let max = Math.max.apply(null, arr);
      this.progressArr = this.errorDetails.map((item, index) => {
        return {
          id: index,
          color: colorKeys[item.errorName],
          text: keys[item.errorName],
          percent: Math.round((item.number / max) * 100),
          number: item.number,
        };
      });
    },
    backHandle() {
      this.backExcepPath.pop();
      let organizationId =
        this.backExcepPath.length > 0
          ? this.backExcepPath[this.backExcepPath.length - 1]
          : '';
      this.getExceptionList({ organizationId });
      this.setBackExcepPath(this.backExcepPath);
    },
  },
};
</script>

<style scoped>
.het1 {
  height: 180px;
}
.wd1 {
  width: 20%;
}
.wd2 {
  width: 20%;
}
.progressor >>> .ant-progress-inner {
  background: transparent;
}
</style>
